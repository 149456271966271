//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'News',
  data () {
    return {
      list: [],
      isLoad:false
    }
  }, mounted () {
    this.getNews()
    // document.addEventListener("scroll", this.handleScroll, true)
  }, methods: {
    handleScroll (event) {
      let target = event.target
      let scrollHeight = target.scrollHeight - target.clientHeight
      console.log(target.scrollHeight ,scrollHeight,scrollHeight - target.scrollTop)
      if (scrollHeight -target.scrollTop < 100 && !this.isLoad){
        console.log("到底了")
      }
    },
    getNews () {
      let that = this
      this.api.newsList().then(response => {
        if (response.result){
          that.list = response.data
        }
      }).catch( response =>{
        console.log(response)
      })
    },
  },
}
