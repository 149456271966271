//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Login',
  data () {
    return {
      CodeBtn: '获取验证码',
      count: 60,
      timer: 0,
      type: false,
      params: {
        phone: '',
        email: '',
        name: '',
        passwd: '',
        vcode: '',
        vid: '',
      },
    }
  },
  methods: {
    getCode () {
      let that = this
      if (that.timer === 0) {
        that.api.getCode({ phone: that.params.phone, type: 2 }).then(response => {
          if (response.result) {
            that.params.vid = response.data.vid
            that.CodeBtn = '60s后重发'
            that.timer = setInterval(() => {
              if (that.count < 1) {
                that.CodeBtn = '获取验证码'
                that.count = 59
                clearInterval(that.timer)
                that.timer = 0
              } else {
                that.CodeBtn = that.count-- + 's后重发'
              }
            }, 1000)
          } else {
            that.$message.error(response.msg)
          }
        })
      }
    },
    forgot () {
      let that = this
      this.api.forgot(this.params).then(response => {
        if (response.result) {
          that.$message.success('修改成功')
          that.type = !that.type
          that.count = 59
          that.CodeBtn = '获取验证码'
          clearInterval(that.timer)
          setTimeout(() => {
            that.$router.push('/login')
          }, 1000)
        }
      })
    },
  },
}
