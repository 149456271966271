//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Home',
  data () {
    return {
      list: [],
    }
  }, mounted () {
    this.getNews()
  }, methods: {
    getNews () {
      let that = this
      this.api.newsList().then(response => {
        that.list = response.data.slice(0, 2)
      })
    },
  },
}
