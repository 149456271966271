//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Banner from '@/components/Banner'

export default {
  name: 'Organization',
  components: { Banner },
}
