//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'HeaderNav',
  data () {
    return {
      nav_list: [
        { name: '首页', path: '/home' },
        { name: '关于大赛', path: '/about_csms' },
        { name: '大赛日程', path: '/timeline' },
        { name: '大赛地点', path: '/matchMap' },
        { name: '大赛报名', path: '/signUp' },
        { name: '赛事动态', path: '/news' },
        { name: '证书查询', path: '/query' },
        { name: '联系我们', path: '/contactUs' },
      ],
      bg: false,
    }
  },
  computed: {
    ...mapState([
      // 映射 this.count 为 store.state.count
      'isLogin','name','avatar'
    ]),
    meta () {
      return this.$router.currentRoute.meta
    },
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)

  },
  methods: {
    //注销登录
    logout(){
      this.$store.commit("logout");
      this.$message.success("您已安全退出")
      localStorage.removeItem("token")
      this.$router.push("/")
    },
    //滚动事件
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.bg = scrollTop > 100
    },
  },
}
