//
//
//

export default {
  name: 'App',
  components: {},
  beforeCreate () {

  },
}
