//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Agreement',
  data () {
    return {
      is_read: false,
      animation: {
        agreement: false,
      },
    }
  },
  mounted () {
    //是否同意过协议

  },
  methods: {
    agree () {
      let that = this
      if (!this.is_read) {
        //勾选协议
        if (!this.animation.agreement) {
          this.$refs.agreement.style.animation = 'shakeX 1s linear'
          this.$refs.agreement_tip.style.animation = 'shakeColor 1s linear'
          this.animation.agreement = true
          setTimeout(function () {
            that.$refs.agreement.style.animation = ''
            that.$refs.agreement_tip.style.animation = ''
            that.animation.agreement = false
          }, 1000)
        }
      } else {
        localStorage.setItem('Agreement', 'true')
        this.$router.push('/signUp')
      }
    },

  },
}
