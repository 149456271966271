//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Login',
  data () {
    return {
      CodeBtn: '获取验证码',
      count: 60,
      timer: 0,
      type: false,
      login_err: '',
      login_params: {
        phone: '',
        passwd: '',
      },
      reg_params: {
        phone: '',
        email: '',
        name: '',
        passwd: '',
        vcode: '',
        vid: '',
      },
    }
  },
  methods: {
    getCode () {
      let that = this
      if (that.timer === 0) {
        that.api.getCode({ phone: that.reg_params.phone, type: 1 }).then(response => {
          if (response.result) {
            that.reg_params.vid = response.data.vid
            that.CodeBtn = '60s后重发'
            that.timer = setInterval(() => {
              if (that.count < 1) {
                that.CodeBtn = '获取验证码'
                that.count = 59
                clearInterval(that.timer)
                that.timer = 0
              } else {
                that.CodeBtn = that.count-- + 's后重发'
              }
            }, 1000)
          } else {
            that.$message.error(response.msg)
          }
        })
      }
    },
    register () {
      let that = this
      this.api.register(this.reg_params).then(response => {
        if (response.result) {
          that.$message.success('注册成功')
          that.type = !that.type
          that.count = 59
          that.CodeBtn = '获取验证码'
          clearInterval(that.timer)
        }
      })
    },
    login () {
      let that = this
      console.log(process.env.VUE_APP_BASE_URL)
      this.api.login(this.login_params).then(function (response) {
        console.log(response)
        if (response.result === 1) {
          //保存用户信息
          that.$store.commit('user_info', response.data)
          //缓存
          localStorage.setItem('token', JSON.stringify(response.data))
          that.$router.push('/')
        } else {
          that.login_err = response.msg
        }
      }).catch(function (error) {
        console.log(error)
      })
    },
  },
}
