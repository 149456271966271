//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Timeline',
  data () {
    return {
      select: 0,
      list: [
        // {
        //   name: '1月7日',
        //   img: '1月7日日程.jpg',
        // },
        // {
        //   name: '1月8日',
        //   img: '1月8日日程.jpg',
        // },
      ],
    }
  },

}
