import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import api from '@/api/api'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import store from '@/store'
Vue.use(ElementUI);
import Banner from '@/components/Banner'
Vue.component("banner", Banner)

document.title =  "CSF-SIM-CSMS中国仿真学会复杂系统建模大赛"
Vue.config.productionTip = false
Vue.prototype.api = api
Vue.prototype.CloudClient = window.CloudClient


new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
