//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Banner from '@/components/Banner'
import About7 from '@/components/About/About7.vue'
import About6 from '@/components/About/About6.vue'
import About5 from '@/components/About/About5.vue'
import About4 from '@/components/About/About4.vue'
import About3 from '@/components/About/About3.vue'
import About2 from '@/components/About/About2.vue'
import About1 from '@/components/About/About1.vue'

export default {
  name: 'Organization',
  components: { About1, About2, About3, About4, About5, About6, About7, Banner },
  data () {
    return {
      isFixed: false,
      list: [
        {
          title: '关于大赛',
          id: 1,
          select: true,
        },
        {
          title: '大赛规则',
          id: 2,
          select: false,
        },
        {
          title: '作品要求',
          id: 3,
          select: false,
        },
        {
          title: '报名指南',
          id: 4,
          select: false,
        },
        {
          title: '作品提交指南',
          id: 5,
          select: false,
        },
        {
          title: '模型上传指南',
          id: 6,
          select: false,
        },
        {
          title: '常见问题',
          id: 7,
          select: false,
        },
      ],
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    //获取参数
    let id = this.$route.query.id
    if (id) {
      this.goTab(id)
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let height = (this.$refs.banner.$el.offsetHeight ?? 450) - 50

      if (scrollTop > height) {
        this.isFixed = true
      } else {
        this.isFixed = false
      }
    },
    goTab (id) {
      this.list.forEach(item => {
        item.select = false
      })
      this.list[id - 1].select = true
      parent.scrollTo(0, this.$refs.top_nav.offsetTop)
    },
    select (info) {
      this.list.forEach(item => {
        item.select = false
      })
      info.select = true
      parent.scrollTo(0, this.$refs.top_nav.offsetTop)
    },
  },
}
