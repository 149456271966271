//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MatchMap',
  data(){
    return{
      map:null
    }
  },
  mounted () {

    window._AMapSecurityConfig = {
      securityJsCode:'f95e2a4325224ae783d69ebc96c29c91',
    }
    this.initAMap()
  },
  methods:{
    initAMap(){
      if (window.AMap){
        this.creatMap()
      }else{
        let url = 'https://webapi.amap.com/maps?v=1.4.15&key=9a1c85740b84edca06c8cd24464d438b';
        let that = this
        let script = document.createElement('script');
        // script.charset = 'utf-8';
        script.src = url;
        document.body.appendChild(script);
        script.onload = ()=>{
          that.creatMap()
        }
      }
    },

    creatMap(){
      this.map = new window.AMap.Map('container',{
        zoom:11,
        center:[117.23,31.82]
      });

      //创建一个点
      let marker = new window.AMap.Marker({
        position: new window.AMap.LngLat(117.23,31.82),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '合肥'
      });
      this.map.add(marker)
    }
  }
}
