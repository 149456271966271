//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'banner',
  mounted () {
    window.addEventListener('resize', () => {
      this.updateHeight()
    }, false)

  },

  data () {
    return {
      bannerHeight: 400,
      list: [{
        img:require('@/assets/b2.png'),
        url:"/signUp"
      },{
        img:require('@/assets/b3.jpg'),
        url:"#"
      }, ],
    }
  },
  methods: {
    updateHeight () {
      this.bannerHeight = this.$refs.img[0].offsetHeight
    },
  },
}
