import axios from 'axios'

import {Message} from 'element-ui'
import {Loading} from 'element-ui'

import store from '@/store'
import router from '@/router'

let request_num = 0
let loadingInstance

function LoadingCheck(type) {
    if (type) {
        request_num++
        if (request_num === 1) {
            loadingInstance = Loading.service({fullscreen: true})
        }
    } else {
        request_num--
        if (request_num === 0) {
            setTimeout(() => {
                loadingInstance.close()
            }, 10)
        }
    }
}

//1. 创建新的axios实例，
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 10 * 1000,
})

// 添加请求拦截器
service.interceptors.request.use(function (config) {
    LoadingCheck(true)
    //如果是api请求 添加token
    if (!config.token){
        config.headers.Authorization = 'Bearer ' + store.state.token
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    LoadingCheck(false)
    if (response.data.result ===1) {
      return response.data
    } else if (response.data.result === 401) {
        Message.error('登陆超时，请重新登录')
        //清空登录信息
        store.commit('logout')
        //localStorage.removeItem("token")
        router.replace({
            path: '/login',
            query: {
                redirect: router.currentRoute.fullPath,
            },
        })
    } else {
        if (response.data.msg) {
            Message.error(response.data.msg)
        }
    }
    return response.data
}, function (error) {
    LoadingCheck(false)
    console.log(error.config)
    if (error.config.token){
        return Promise.reject(error)
    }
    if (error && error.response) {
        Message.error('服务错误:' + error.response.status)
    }else{
        Message.error('请求失败')
    }
    return Promise.reject(error)
})

export default service