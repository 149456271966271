import VueRouter from 'vue-router'
import Vue from 'vue'
import Login from '@/components/Login'
import Index from '@/components/Index'
import Home from '@/components/pages/Home'
import Organization from '@/components/pages/Organization'
import Timeline from '@/components/pages/Timeline'
import MatchMap from '@/components/pages/MatchMap'
import News from '@/components/pages/News'
import NewsInfo from '@/components/pages/NewsInfo'
import AboutUs from '@/components/pages/AboutUs'
import SignUp from '@/components/pages/SignUp'
import Agreement from '@/components/pages/Agreement'
import store from '@/store'
import { Message } from 'element-ui'
import Forgot from '@/components/Forgot'
import Query from '@/components/pages/Query'
import AboutCSMS from '@/components/pages/AboutCSMS.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Index,
    redirect:"home",
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: 'home',
        component: Home,
        meta: {
          requiresAuth: false,
          navId: 0,
        },
      },
      {
        path: 'organization',
        component: Organization,
        meta: {
          requiresAuth: false,
          navId: 1,
        },
      },
      {
        path: 'about_csms',
        component: AboutCSMS,
        meta: {
          requiresAuth: false,
          navId: 1,
        },
      },
      {
        path: 'timeline',
        component: Timeline,
        meta: {
          requiresAuth: false,
          navId: 2,
        },
      },
      {
        path: 'query',
        component: Query,
        meta: {
          requiresAuth: false,
          navId: 2,
        },
      },
      {
        path: 'matchMap',
        component: MatchMap,
        meta: {
          requiresAuth: false,
          navId: 3,
        },
      },
      {
        path: 'signUp',
        component: SignUp,
        meta: {
          requiresAuth: true,
          navId: 3,
        },
      },
      {
        path: 'news',
        component: News,
        meta: {
          requiresAuth: false,
          navId: 3,
        },
      },
      {
        path: 'news/:id',
        component: NewsInfo,
        props: true,
        meta: {
          requiresAuth: false,
          navId: 0,
        },
      },
      {
        path: 'contactUs',
        component: AboutUs,
        meta: {
          requiresAuth: false,
          navId: 0,
        },
      },
      {
        path: 'signUp/Agreement',
        component: Agreement,
        meta: {
          requiresAuth: false,
          navId: 0,
        },
      }
      ],
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/forgot',
    component: Forgot,
  },
]

const router = new VueRouter({
  mode:'history',
  routes, // (缩写) 相当于 routes: routes
})

// 验证登录
router.beforeEach((to, from, next) => {
  if (!store.state.isLogin) {
    let data = localStorage.getItem('token')
    if (data) {
      let info = JSON.parse(data)
      store.commit('user_info', info)
    }
  }
  let isAuthenticated = store.getters.isLogin
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    Message.error("请先登录")
    next('/login' )
  } else next()
})

export default router