import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: '',
    name: '',
    avatar: '',
    isLogin: false,
  },
  mutations: {
    user_info (state, info) {
      state.token = info.token
      state.name = info.name
      state.avatar = info.avat ? info.avat : require('@/assets/avatar.jpg')
      state.isLogin = true
    },
    logout (state) {
      state.token = ""
      state.isLogin = false
    },
  },
  getters: {
    token: state => {
      return state.token
    },
    isLogin: state => {
      return state.isLogin
    },
  },
})

export default store