//
//
//
//
//
//
//
//
//

import HeaderNav from '@/components/HeaderNav'
import HeaderNavM from '@/components/HeaderNav_m'
import FooterBox from '@/components/FooterBox'

export default {
  name: 'Index',
  components: { FooterBox, HeaderNav ,HeaderNavM},
}
