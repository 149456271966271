//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Banner from '@/components/Banner'

export default {
  name: 'Organization',
  components: { Banner },
  data () {
    return {
      match_type: ['初赛证书', '决赛证书'],
      params: {
        team_type: 0,
        code: '',
      },
      cert_info: {},
    }
  },
  methods: {
    getCertInfo () {

      this.api.getCertInfo({
        'certi_code': this.params.code,
      }).then(response => {
        this.cert_info = response.data
        console.log(response)
      })
    },
  },
}
