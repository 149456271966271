//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SignUp',
  data () {
    return {
      isDeadline:false,
      cloud_tips: false,
      match_type: ['本科生赛道', '研究生赛道','企业赛道'],
      user_type: ['指导教师', '队长', '副队长', '队员'],
      model_type_list: [
        '供应链', '生产制造', '交通运输', '仓储', '铁路物流', '矿业', '石油/天然气', '港口码头',
        '道路交通', '客运枢纽', '医疗', '业务流程', '资产管理', '市场营销', '社会进程', '军事防御', '其他'],
      recommender_type: ['中国仿真学会', '北京格瑞纳电子产品有限公司'],
      dialog: {
        addPerson: false,
        work: false,
      },
      animation: {
        agreement: false,
        person: false,
      },
      params: {
        team_name: '',
        unit_name: '',
        phone: '',
        team_type: 0,
        is_read: false,
      },
      teamPerson: {
        name: '',
        email: '',
        phone: '',
        type: 0,
      },
      check: {
        person: {
          name: false,
          phone: false,
          email: false,
          type: false,
        },
        team_name: false,
        unit_name: false,
        phone: false,
      },
      teamList: [],
      isSignUp: false,
      isWork: false,
      edit: false,
      model_params: {
        cloudKey: '',
        model_list: [{ name: '请先填写AnyLogic Cloud API Key' }],
        model_name: '',
        filePath: '',
        file_name: '',
        model_type: 0,
        model_id: 0,
        recommender: 0,
      },
    }
  }, mounted () {
    let deadline =new Date("2023-8-01 00:00:00");
    if (deadline<= (new Date())){
      this.isDeadline = true
    }
    this.getMatch()
  },
  watch: {
    'model_params.cloudKey': {
      handler (val) {
        let that = this
        let keyCheck = /^([\w\d]{4,12}-){4}[\w\d]{12}$/
        console.log(val)
        if (keyCheck.test(val)) {
          setTimeout(function () {
            that.getModelList()
          }, 500)
        }
      },
      immediate: true,
    },
  },
  methods: {
    getModelList () {
      let that = this
      this.api.getModels(this.model_params.cloudKey).then(ret => {
        console.log(ret)
        if (ret.length < 1) {
          this.$message.error('未获取到模型列表')
        } else {
          that.model_params.model_list = ret
          that.model_params.model_list.forEach(function (item, index) {
            if (item.name === that.model_params.model_name) {
              that.model_id = index
            }
          })
        }
      }).catch(ret => {
        this.$message.error('ApiKey不正确')
        switch (ret.status) {
          case 401:
            this.$message.error('ApiKey不正确')
        }
        console.log(ret)
      })
    },
    getMatch () {
      let that = this
      this.api.getMatch().then(response => {
        if (response.result !== 1) return
        if (response.data) {
          if (response.data.team_name && response.data.team_name.length > 0) {
            that.params = response.data
            that.teamList = response.data.team_info
            that.isSignUp = true
            that.getModelInfo()
          } else {
            if (!localStorage.getItem('Agreement')) {
              this.$router.push('signUp/Agreement')
            } else {
              localStorage.removeItem('Agreement')
            }
          }
        } else {
          if (!localStorage.getItem('Agreement')) {
            this.$router.push('signUp/Agreement')
          } else {
            localStorage.removeItem('Agreement')
          }
        }
      })
    },
    getModelInfo () {
      let that = this
      this.api.getModelInfo().then(response => {
        console.log(response)
        if (response.data && response.data.cloudkey) {
          that.model_params.cloudKey = response.data.cloudkey
          //寻找模型
          for (const i in that.model_list) {
            if (that.model_list[i].name === response.data.model_name) {
              that.model_params.model_id = i
              break
            }
          }
          that.isWork = true
          that.model_params.recommender = response.data.recommender
          that.model_params.model_type = response.data.model_type
          that.model_params.filepath = response.data.filepath
          that.model_params.file_name = response.data.filename
        }
      })
    },
    showAddPerson () {
      this.teamPerson = {
        name: '',
        email: '',
        phone: '',
        type: 0,
      }
      this.dialog.addPerson = true
    },
    sendForm () {
      let that = this
      let phone_ = /^(1[0-9][0-9])\d{8}$/
      let isCheck = true
      //队名
      if (this.params.team_name.length < 3) {
        this.check.team_name = true
        isCheck = false
      } else {
        this.check.team_name = false
      }
      //队名
      if (this.params.unit_name.length < 3) {
        isCheck = false
        this.check.unit_name = true
      } else {
        this.check.unit_name = false
      }
      //队名
      if (this.params.phone.length < 3 || !phone_.test(this.params.phone)) {
        this.check.phone = true
        isCheck = false
      } else {
        this.check.phone = false
      }
      if (this.teamList.length <= 0) {
        if (!this.animation.person) {
          this.$refs.no_person.style.animation = 'shakeX 1s forwards'
          setTimeout(function () {
            that.$refs.no_person.style.animation = ''
            that.animation.person = false
          }, 1000)
        }
        isCheck = false
      }

      if (!isCheck) {
        return
      }
      //提交报名
      this.api.updateMatch({
        team_type: this.params.team_type,
        team_name: this.params.team_name,
        unit_name: this.params.unit_name,
        phone: this.params.phone,
        team_info: this.teamList,
      }).then(response => {
        console.log(response)
        this.$message.success('提交成功')
        that.isSignUp = true
        that.edit = false
      })

    },
    addTeamPersons () {
      let phone_ = /^(1[0-9][0-9])\d{8}$/
      let email_ = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      this.check.person = {
        name: false,
        phone: false,
        email: false,
      }
      switch (true) {
        case (this.teamPerson.name.length < 2):
          this.check.person.name = true
          break
        case (this.teamPerson.phone.length > 0 && !phone_.test(this.teamPerson.phone)):
          this.check.person.phone = true
          break
        case (this.teamPerson.email.length > 0 && !email_.test(this.teamPerson.email)):
          this.check.person.email = true
          break
        case this.getPersonsNum(0) >= 1  && this.teamPerson.type === 0:
          this.check.person.type = true
          break
        default:
          this.teamList.push({ ...this.teamPerson })
          this.dialog.addPerson = false
          break
      }
    },
    getPersonsNum(type){
      let i = 0
      this.teamList.forEach(item=>{
        if(item.type === type){
          i++
        }
      })
      return i
    },
    delTeamPersons (index) {
      this.teamList.splice(index, 1)
    },
    sendWork () {
      let that = this
      this.model_params.model_name = this.model_params.model_list[this.model_params.model_id].name
      this.model_params.fileName = this.model_params.file_name
      if (this.model_params.file_name === '') {
        that.$message.error('请选择pdf文件')
        return
      }
      if (this.model_params.model_name === '请先填写AnyLogic Cloud API Key') {
        that.$message.error('请选择模型')
        return
      }
      this.api.sendWork(this.model_params).then(response => {
        console.log(response)
        that.isWork = true
        that.$message.success('提交成功')
        that.dialog.work = false
      })
    },
    upload (event) {
      let that = this
      let files = event.target.files
      if (files.length === 0) {
        that.$message.error('请选择pdf文件')
        return
      }
      let formdata = new FormData()
      for (let i = 0; i < files.length; i++) {
        let imgName = files[i].name
        if (files[i].size > 1024 * 1024 * 20) {
          that.$message.error('文件太大了,请上传小于20M的文件')
          return
        }
        let idx = imgName.lastIndexOf('.')
        if (idx != -1) {
          var ext = imgName.substr(idx + 1).toUpperCase()
          ext = ext.toLowerCase()
          if (ext != 'pdf') {
            that.$message.error('格式不正确,支支持PDF上传')
            return
          }
          this.model_params.file_name = imgName
          formdata.append('file', files[i])
        }
      }
      this.api.upload(formdata).then(function (response) {
        console.log(response)
        if (response.result === 1) {
          that.$message.success('上传成功')
          that.model_params.filePath = response.data.url
        } else {
          alert(response.data.msg)
        }
      }).catch(function (error) {
        console.log(error)
        alert('上传出错')
      })
    },
  },
}
