//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'NewsInfo',
  props: [
    'id',
  ],
  data () {
    return {
      info: {},
    }
  }, mounted () {
    this.getInfo()
  }, methods: {
    getInfo () {
      let that = this
      this.api.newsInfo(this.id).then(response => {
        if (response.result) {
          that.info = response.data
        }
      }).catch(response => {
        console.log(response)
        setTimeout(() => {
          that.$router.push('/news')
        }, 1000)
      })
    },
  },
}
