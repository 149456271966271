import http from './request'

let request = '/api'

const api = {
  login (params) {
    return http.post(`${request}/login`, params)
  },
  forgot (params) {
    return http.post(`${request}/forgot`, params)
  },
  newsList (params) {
    return http.post(`${request}/news`, params)
  },
  newsInfo (id) {
    return http.post(`${request}/mnews/` + id, {})
  },
  updateMatch (params) {
    return http.post(`${request}/update_match`, params)
  },
  getMatch (params) {
    return http.post(`${request}/match`, params)
  },
  upload (params) {
    return http.post(`${request}/upload`, params)
  },
  sendWork (params) {
    return http.post(`${request}/update_model`, params)
  },
  getCode (params) {
    return http.post(`${request}/vcode`, params)
  },
  register (params) {
    return http.post(`${request}/register`, params)
  },
  getModelInfo (params) {
    return http.post(`${request}/model`, params)
  },
  getCertInfo (params) {
    return http.post(`${request}/checkcertif`, params)
  },
  getModels (apiKey) {
    return http.get(`https://cloud.carila.cn/api/open/8.5.0/models`, {}, {
      token: true,
      headers: {
        'Authorization': apiKey,
      },
    })
  },
}
//导出
export default api